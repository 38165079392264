import { useCallback, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Container, Box,Typography, Paper, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

/**
 * Represents the component for displaying a success message after a payment is made.
 * @returns {JSX.Element} The JSX element containing the success message.
 */
const Success = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const apartmentID = searchParams.get("apartment");
  const security = searchParams.get("security");
  const token = searchParams.get("token");
  const free = searchParams.get("free");
  
  const [message, setMessage] = useState({ error: false, message: ""});

  const setAparmentPaid = useCallback(() => {
    // Do async request to set Apartment paid as true.
    const getApartment = async () => {
      const paymentGetResult = await fetch(`/api/payments/${security}`, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          "token": token,
        }
      });
      let payment = await paymentGetResult.json();
      console.log(payment);
      if(payment.result === "success") {
        const getResponse = await fetch(`/api/apartments/${apartmentID}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "token": token,
          }
        });
        console.log(getResponse);
        let getData = await getResponse.json();
        console.log(getData);
        if(getData.message === "success") {
          if(getData.apartment.paid === true) {
            setMessage({ error: true, message: "Apartment has already been published." });
            return;
          }
          getData.apartment.paid = true;
          const putResponse = await fetch(`/api/apartments/${apartmentID}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "token": token,
            },
            body: JSON.stringify(getData.apartment),
          });
          console.log(putResponse);
          const putData = await putResponse.json();
          console.log(putData);
          if(putData.message === "Apartment updated.") {
            const paymentPostResult = await fetch(`/api/payments/`, {
              method: "PUT",
              headers: {
                "Content-type": "application/json",
                "token": token,
              },
              body: JSON.stringify({ code : payment.message.code })
            });
            payment = await paymentPostResult.json();
            console.log(payment);
            if(payment.result === "success") {
              setMessage({ error: false, message: "Apartment published." });
            }
          } else {
            setMessage({ error: true, message: "Could not publish apartment."});
          }
        } else {
          setMessage({ error: true, message: getData.message });
        }
      }
      
    }
    getApartment();
  },[apartmentID, security, token]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setAparmentPaid, [setAparmentPaid]);

  return (
    <Container maxWidth="md" sx={{mt: 4, pb: 4, mb: 4}} component={Paper}>
      <Box sx={{ marginTop: "40px", flexGrow: 1}}>
        {(message.message === "Apartment published." || message.message === "Apartment has already been published.") 
        ? <Grid container spacing={2} sx={{pt:3}}>
            <Grid item xs={12}>
              { (free == undefined)
              ? <Typography variant="h4" component="h1" sx={{ color: "green" }} gutterBottom>
                  Haluamme ilmoittaa, että maksusi onnistui.
                </Typography>
              : (free == "month") 
              ? <Typography variant="h4" component="h1" sx={{ color: "green" }} gutterBottom>
                  Haluamme ilmoittaa, että ilmoituksesi on julkaistu ilmaiseksi.
                </Typography>
              : (free == "third")
              && <Typography variant="h4" component="h1" sx={{ color: "green" }} gutterBottom>
                  Haluamme ilmoittaa, että ilmoituksesi on julkaistu ilmaiseksi.
                </Typography>
              }
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" component="h2" gutterBottom>
                Ilmoituksesi on julkaistu.
              </Typography>
              <Typography variant="body1" component="p" gutterBottom>
                Olemme kiitollisia vuokrausilmoituksen julkaisusta,
                toivomme että löydämme sinulle pian vuokralaisen.
                Muista kuitenkin tarkistaa vuokrausilmoituksesi 
                hakemukset säännöllisesti.
              </Typography>
              <Typography variant="body1" component="p" gutterBottom>
                Toivotan sinulle hyvää päivän jatkoa.
              </Typography>
              <Typography variant="body1" component="p" gutterBottom>
                Ystävällisin terveisin,
                Vuokrapaja
              </Typography>
              <Button sx={{ mb: 2}} fullWidth variant="contained" color="info" onClick={() => { navigate("/company/apartments")}}>
                Palaa ilmoituksiisi.
              </Button>
            </Grid>
          </Grid>
        : ( message.message === "Could not publish apartment.") ? 
          <Grid container spacing={2} sx={{pt:3}}>
            <Grid item xs={12}>
              <Typography variant="h4" component="h1" sx={{ color: "RED" }} gutterBottom>
                Kriittinen virhe tapahtui, maksusi ei onnistunut.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p" gutterBottom>
                Ota heti meihin Vuokrapajaan yhteyttä ja kerro maksutietosi 
                ja { apartmentID } numero, jotta voimme manuaalisesti hoitaa
                vuokrailmoituksesi.
              </Typography>
              <Typography variant="body1" component="p" gutterBottom>
                Ystävällisin terveisin,
                Vuokrapaja
              </Typography>
            </Grid>
          </Grid>
        : <Grid container spacing={2} sx={{pt:3}}>
          </Grid>
        }
      </Box>
    </Container>
  );
}

export default Success;